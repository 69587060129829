$product-color-variant-size: 40px;
$product-size-variant-size: 39px;
$product-border-selected-variant: 2px solid #2A41BB;

%element-missing-strikethrough {
    background: #4F4F4F;
    position: absolute;
    height: 2px;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
}

.product-variants {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    &__recommendations {
        margin-top: 6px;
        margin-bottom: 12px;
    }

    &__listing {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        .product-variant__color {
            padding: 4px 4px 4px 0;

            &--spot {
                height: 18px;
                width: 18px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover, &:focus {
                    text-decoration: none;
                    color: inherit;
                    border-color: #222 !important;
                }
            }
        }

        .product-variant__button {
            height: 24px;
            font-size: 11px;
            border-width: 1px;
            padding: 7px 6px;
            margin-right: 5px;
            margin-bottom: 5px;

            &:hover, &:focus {
                text-decoration: none;
                color: inherit;
                border-color: #222 !important;
            }
        }

        .product-variant__image {

            &--square {
                border: 1px solid transparent;

                &:hover {
                    border: 1px solid black;
                }

                & > img {
                    width: 30px;
                }
            }
        }

        &--more {
            color: #222;
            border-color: #CCCCCC !important;
            font-size: 16px;

            &:hover, &:focus {
                text-decoration: none;
                color: inherit;
            }
        }
    }

    &__title {
        display: flex;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 400;
    }

    &__selected {
        text-transform: none;
        font-weight: 600;
    }

    &__body {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 32px;
    }

    &__show-more {
        margin-left: auto;
    }

    &__deck {
        .ubo-center-position-wrapper {
            position: fixed !important;
            right: 0;
            top: 0;
            bottom: 0;

            @media(max-width: $screen-xs-max) {
                left: 0;
            }

            .ubo-box {
                width: 498px;
                height: 100%;
                max-height: none !important;
                padding: 0;
                overflow: hidden;

                @media(max-width: $screen-xs-max) {
                    width: 100%;
                    max-width: none !important;
                }

                .ubo-header {
                    &-text {
                        display: none;
                    }
                }

                .ubo-close-button {
                    font-size: 18px;
                    right: 10px;
                    top: 20px;
                }

                .ubo-content {
                    display: flex;
                    padding: 50px;
                    margin: 0;
                    height: 100%;
                    justify-content: center;

                    @media(max-width: $screen-xs-max) {
                        padding: 50px 15px 15px;
                    }

                    .throbber {
                        align-self: center;
                    }
                }
            }
        }

        &-title {
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 30px;
        }

        &-container {
            overflow-y: auto;
            padding-right: 10px;
        }

        &-body {
            display: flex;
            flex-direction: column;
            flex: 1 1 100%;
        }

        &-row {
            cursor: pointer;
            display: flex;
            align-items: center;
            border-top: 1px solid #767676;
            padding-top: 4px;
            padding-bottom: 4px;
            font-size: 12px;
            line-height: 16px;

            &, &:hover, &:focus, &:active {
                color: #222 !important;
                text-decoration: none;
            }

            &:hover {
                font-weight: 700;
            }
        }

        &-name {
            flex: 1 1 100%;
            margin-left: 20px;
        }

        &-price {
            flex: 0 1 80px;
            margin-left: auto;
            text-align: right;
        }
    }
}

.product-variant {
    &__pointer {
        cursor: pointer;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.75px solid #EBEBEB;
        font-size: 16px;
        font-weight: 600;
        margin: 8px 16px 8px 0;
        overflow: hidden;
        padding: 16px;
        position: relative;
        border-radius: 8px;
        min-width: 94px;
        height: 53px;

        label {
            margin-bottom: 0;
            display: inline-flex;
            gap: 8px;
            pointer-events: none;

            input {
                margin: 0 0 0;
                width: 21px;
                height: 21px;
                accent-color: #2A41BB;
            }
        }

        &, &:hover {
            text-decoration: none;
            color: #222;
        }

        &--no-border {
            border-color: transparent;
        }

        &--selected {
            border-color: #2A41BB;
            background: #F4F6FE;
            box-shadow: 0px 0px 0px 2px rgba(35, 13, 192, 0.25);

            &, &:hover {
            }
        }

        &--missing {
            @extend %element-missing-strikethrough;
            border-color: #F5F5F5;
            background: #EBEBEB;
            opacity: 0.5;
            margin: auto 3px;
        }
    }

    &__color {
        padding: 4px;
        margin: 2px 4px 2px 0;
        position: relative;

        &--recommendations {
            border: 0 !important;
        }

        &--selected {
            padding: 3px;
            border: $product-border-selected-variant;
            border-radius: $product-color-variant-size;

            &.product-variant__color--square {
                border-radius: 6px;
            }
        }

        &--spot {
            display: block;
            border-radius: $product-color-variant-size;
            border: 1px solid;
            height: $product-color-variant-size;
            width: $product-color-variant-size;

            & div {
                width: 100%;
                height: 100%;
                border-radius: $product-color-variant-size;
            }
        }

        &--square {
            border-radius: 0;

            a, div {
                border-radius: 0;
            }

            .product-variant__color--missing {
                width: calc(100% + 10px);
                margin-left: -5px;

                & > div {
                    width: 91%;
                }
            }
        }

        &--missing {
            @extend %element-missing-strikethrough;
            background: white;
            width: 100%;
            height: 4px;
            transform: rotate(-45deg);
            display: flex;
            align-items: center;
            justify-content: center;

            & > div {
                height: 2px;
                width: 85%;
                background: #4F4F4F;
            }
        }

        & > &--spot {
            cursor: pointer;
        }
    }

    &__image {
        width: 75px;
        position: relative;

        & > img {
            max-width: 100%;
            max-height: 100%;
        }

        &--selected {
            padding: 2px;
            border: $product-border-selected-variant;
        }

        &--missing {
            @extend %element-missing-strikethrough;
            height: 2px;
            width: calc(100%);
            transform: rotate(-45deg);
        }

        &--deck > img {
            width: 75px;
        }
    }
}
